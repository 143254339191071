var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', [_c('c-stack', {
    attrs: {
      "is-inline": "",
      "spacing": ['0', '20px']
    }
  }, [_c('c-button', {
    staticClass: "m-tabs",
    attrs: {
      "as": "router-link",
      "to": {
        name: 'nutri.progress-tracker.indikator'
      },
      "variant": "ghost",
      "rounded": "0",
      "w": ['50%', 'auto'],
      "white-space": "braek-space",
      "font-size": "12px"
    }
  }, [_vm._v(" Indikator Progress & Tracker ")]), _c('c-button', {
    staticClass: "m-tabs",
    attrs: {
      "as": "router-link",
      "to": {
        name: 'nutri.progress-tracker.master'
      },
      "variant": "ghost",
      "rounded": "0",
      "w": ['50%', 'auto'],
      "white-space": "braek-space",
      "font-size": "12px"
    }
  }, [_vm._v(" Master Progress & Tracker ")])], 1), _c('router-view')], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }